const mobileOffset = 126
const mobileMaxWidth = 619
const desktopOffset = 68

stickySidebarInit = function (topSpacing = desktopOffset, sidebarSelector) {
  const sidebarSelectorChild = sidebarSelector.querySelector('.js-sticky-sidebar-inner')
  const sidebarProducts = document.querySelector('.js-sticky-sidebar-products')

  const sidebarIsNeeded = (sidebarProducts.offsetHeight > sidebarSelectorChild.offsetHeight)
  
  if (sidebarSelector && sidebarIsNeeded) {
    window.filterSidebar = new StickySidebar(sidebarSelector, {
      topSpacing: topSpacing,
      bottomSpacing: 240,
      containerSelector: '.js-sticky-sidebar-wrapper',
      innerWrapperSelector: '.sidebar__inner',
      resizeSensor: true
    })
    
    sidebarSelector.addEventListener('affixed.container-bottom.stickySidebar', function (event) {
      sidebarSelector.classList.add('no-padding')
      console.log('affixed.container-bottom.stickySidebar')
    });

    sidebarSelector.addEventListener('affixed.top.stickySidebar', function (event) {
      console.log('affixed.top.stickySidebar')
      sidebarSelector.classList.remove('no-padding')
    });
  }
}

debounce = function (func, time = 100){
  let timer;
  return function(event){
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, time, event);
  };
}

stickySidebarRestart = function () {
  if (window.filterSidebar) {
    window.filterSidebar.destroy();
  }
  if (window.innerWidth > 619) {
    stickySidebarInit(desktopOffset)
  } else {
    stickySidebarInit(mobileOffset)
  }
}

document.addEventListener("DOMContentLoaded", function() {
  const topSpacing = window.innerWidth > mobileMaxWidth ? desktopOffset : mobileOffset
  const sidebarSelector = document.querySelector('.js-sticky-sidebar-content')
  
  if (sidebarSelector) {
    stickySidebarInit(topSpacing, sidebarSelector)
  }
});

window.addEventListener('resize', debounce(stickySidebarRestart, 150))
